import {Project} from "../model/types";
import {
    DOCKER,
    GRADLE,
    HYBRIS,
    J_QUERY,
    JAVA,
    JAVA_FX,
    JAVA_SCRIPT,
    JAVA_SWING,
    JEE, KUBERNETES,
    MAVEN,
    MONGO_DB,
    ORACLE,
    POSTGRES, QUARKUS,
    REACT,
    SPRING,
    SPRING_CORE, TYPE_SCRIPT,
    VUE,
    CSS,
} from "./Skills";

export const projects: Project[] = [
    {
        start: new Date(2022, 1),
        end: new Date(2025, 1) ,
        type: "self-employed",
        title: "Fullstack Developer",
        text: ["Neuaufbau eines Portals zur Authentifizierung für Dienste des öffentlichen Dienstes. Hierbei Verwantwortung für die Entwicklung des Frontends und die gesamte Architektur des Projektes."],
        skills: [VUE,TYPE_SCRIPT, JAVA_SCRIPT,  JAVA, CSS, SPRING, DOCKER,  KUBERNETES, ORACLE, MAVEN],
        responsibilities: ["Fullstack-Entwicklung, Architektur"],
        field: "Öffentlicher Dienst",
    },
    {
        start: new Date(2021, 8),
        end: new Date(2021, 11),
        type: "self-employed",
        title: "Senior Developer",
        text: ["Weiterentwicklung einer Web-Shop-Lösung zur Verwaltung von Kantinen."],
        skills: [HYBRIS, JAVA, SPRING, J_QUERY, JAVA_SCRIPT, CSS],
        responsibilities: ["Alleinverantwortliche Entwicklung eines Feature-Sets"],
        field: "E-Commerce",
    },
    {
        start: new Date(2021, 5),
        end: new Date(2021, 8),
        type: "Employed",
        title: "Senior Developer",
        text: ["Modernisierung und Weiterentwicklung einer Applikation zur Konfiguration und zum Verkauf von Fahrzeugen",
            "Migration von UI-Komponenten von JavaSwing auf JavaFX",
            "Refactoring der Anwendung um JavaEE Konformität herzustellen"],
        skills: [JAVA, JEE, QUARKUS, ORACLE, JAVA_SWING, JAVA_FX, GRADLE],
        responsibilities: ["Team-Lead", "Entwicklung", "Architektur"],
        field: "Automotive",
    },
    {
        start: new Date(2021, 3),
        end: new Date(2021, 5),
        type: "Employed",
        title: "Senior Developer",
        text: ["Entwicklung und Wartung eines großen Webshops für eine schweizer Kaufhauskette."],
        skills: [HYBRIS, JAVA, SPRING, SPRING_CORE, JAVA_SCRIPT, CSS, ORACLE],
        responsibilities: ["Entwicklung"],
        field: "E-Commerce",
    },
    {
        start: new Date(2020, 7),
        end: new Date(2021, 3),
        type: "Employed",
        title: "Senior Developer",
        text: ["Entwicklung eines Backoffice Tools zur Festlegung und Verwaltung von Server und Api Konfigurationen"],
        skills: [JAVA, SPRING, JAVA_SCRIPT, CSS, VUE, MONGO_DB, MAVEN, DOCKER],
        responsibilities: ["Entwicklung", "Architektur", "Einarbeitung und Schulung von Kollegen"],
        field: "Automotive",
    },
    {
        start: new Date(2018, 8),
        end: new Date(2020, 6),
        type: "Employed",
        title: "Lead Developer",
        text: ["Entwicklung eines Flottenmanagementsystems zum Verleih von Fahrzeugen an Autovermieter",
            "Möglichst automatische Verarbeitung der Fahrzeugen über den kompletten Zeitraum von der Buchung bis zur Rückgabe.",
            "Hierbei Kommunikation mit verschiedenen Fremdsystemen.",
            "Möglichkeit für den User den Prozess zu überwachen und gegebenenfalls einzugreifen"],
        skills: [JAVA, SPRING, TYPE_SCRIPT, JAVA_SCRIPT, CSS, REACT, POSTGRES, MAVEN, DOCKER],
        responsibilities: ["Entwicklung", "Architektur"],
        field: "Telekommunikation",
    },
]